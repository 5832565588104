.stars {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.star {
  display: flex;
  flex-wrap: wrap;
}

.gold {
  color: gold;
}

.gray {
  color: gray;
}