#landing {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
}

#landing-text {
  font-size: 2em;
  margin: auto;
  color: var(--gunmetal);
  text-align: center;
}

#up-arrow {
  background: var(--light);
  background: none;
  border-radius: 50% 50% 0 0;
  font-size: 3em;
  width: 50px;
  height: 45px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  color: #2E4052;
  z-index: 100;
}

