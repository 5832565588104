@import url(https://fonts.googleapis.com/css?family=Share+Tech&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 100%;
  height: 100%
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: 'POLYA Regular';
  src: url(/static/media/POLYA.f57cd3a5.eot?#iefix) format('embedded-opentype'),
  url(/static/media/POLYA.29c92e82.woff) format('woff'),
  url(/static/media/POLYA.57ea44bb.otf) format('otf'),
  url(/static/media/POLYA.66c022ea.svg#svgFontName) format('svg');
}

#root {
  font-family: 'Share Tech', sans-serif;
  --gunmetal: #011b25;
  --green-blue: #476A6F;
  --light-green: #b7d2d8;
  --light-blue: #8DA7BE;
  --alabaster: #F2F0E6;
  --light: #D7E1E5;
  --electric-green: #cdedf8;
  height: 100%;
  width: 100%;
}

#body {
  position: fixed;
  left: 0;
  top: 0;
  margin-top: 5vh;
  width: 100%;
  height: 95%;
  overflow-x: hidden;
  overflow-y: visible;
  scroll-behavior: smooth;
}
#toggle-button {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 5vh;
  width: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
  margin-right: 2vw;
}

#toggle-button:focus {
  outline: none;
}

.toggle-line {
  width: 100%;
  height: 5px;
  background: var(--gunmetal);
}

#toggle-button:hover .toggle-line {
  background: var(--green-blue);
}

@media (min-width: 678px) {
  #toggle-button {
    display: none;
  }
}

.side-bar {
  height: 100%;
  width: 60%;
  max-width: 300px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 3;
  background-color: var(--light);
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
}

.side-bar.open {
  transform: translateX(0);
}

.side-item {
  font-size: 1.2em;
  z-index: 200;
  cursor: pointer;
  width: 100%;
  text-align: center;
  margin-top: 1vh;
}

.side-item > a {
  text-decoration: none;
  color: var(--gunmetal);
}

.side-item > a:hover {
  color: var(--green-blue)
}

.backdrop {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.3);
  z-index: 2;
}
#nav {
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  height: 5vh;
  display: flex;
  align-items: center;
  z-index: 200;
  background: none;
}

#navbrand > a {
  cursor: pointer;
  text-decoration: none;
  font-size: 2.7em;
  margin-left: 1vw;
  font-family: "POLYA Regular";
  color: var(--gunmetal)
}

#spacer {
  flex: 1 1;
}

#nav-items {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

.nav-item {
  padding: 0 2rem;
  text-decoration: none;
  font-size: 1.2em;
  z-index: 200;
  cursor: pointer;
}

.nav-item > a {
  text-decoration: none;
  color: var(--gunmetal)
}

.nav-item > a:hover {
  color: var(--green-blue)
}

@media (max-width: 678px) {
  #nav-items {
    display: none;
  }
}

#footer {
  position: fixed;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  align-items: center;

  z-index: 100;
}

.footer-img {
  padding: 5px;
  height: 36px;
  width: auto;
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}

@media (max-width: 678px) {
  #footer {
    flex-direction: column;
  }
}



.particles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--light);
  z-index: -100;
}
#landing {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
}

#landing-text {
  font-size: 2em;
  margin: auto;
  color: var(--gunmetal);
  text-align: center;
}

#up-arrow {
  background: var(--light);
  background: none;
  border-radius: 50% 50% 0 0;
  font-size: 3em;
  width: 50px;
  height: 45px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  color: #2E4052;
  z-index: 100;
}


.typed {
  display: flex;
  align-items: center;
}

.caret {
  width: 32px;
  height: 45px;
}
.section {
  min-height: 100%;
  min-height: -webkit-fill-available;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  color: var(--light);
  background: var(--gunmetal);
}

.section-title {
  font-size: 4em;
  font-weight: bold;
  text-align: center;
  height: 10%;
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.section-body {
  width: 55%;
  padding-bottom: 8%;
  padding-top: 2%;
  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section-title > div:hover {
  color: var(--electric-green);
}

.section-title > div {
  transition: .25s ease;
}

@media (max-width: 678px) {
  .section-title > div:hover {
    color: var(--light);
  }
}
.body-text {
  text-align: center;
  margin-bottom: 1%;
}

#about-img {
  min-width: 300px;
  max-width: 500px;
  height: auto;
  border-radius: 5%;
  overflow: hidden;
}

#about-img > img {
  width: 100%;
  height: 100%;
}
.skill {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  padding-bottom: 3%;
  padding-top: 1%;
}

.dropdown {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: all 0.35s ease-out;
}

.skill:hover .dropdown {
  height: 1.3em;
}

.show {
  height: 1.3em;
}

.skill-name {
  font-size: 1.5em;
}

@media (max-width: 678px) {
  .dropdown {
    height: 1.3em;
  }
}


.stars {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.star {
  display: flex;
  flex-wrap: wrap;
}

.gold {
  color: gold;
}

.gray {
  color: gray;
}
.list-title {
  font-size: 2em;
  border-bottom: solid var(--green-blue);
  text-align: center;
  width: 60%;
}

.skill-list {
  height: 300px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5%;
}

@media (max-width: 678px) {
  .skill-list {
    height: auto;
  }
}


#skills > .section-body {
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
}

#projects > .section-body > .btn {
  margin: 5% auto;
}

.react-reveal {
  width: 100%;
  height: 100%;
}

#projects-body {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

[class~='project-card']:last-of-type {
  border-bottom: none;
}

.project-card {
  display: inline-grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-column-gap: 5px;
  width: 100%;
  height: 275px;
  padding-bottom: 2.5%;
  margin-bottom: 2.5%;
  border-bottom: dotted;
}

.project-img {
  grid-column: 1 / 7;
  grid-row: 1 / -1;
  overflow: hidden;
  /* border: solid var(--green-blue); */
  /*border-radius: 5px;*/
  /* position: relative; */
}

.project-img > img {
  height: 100%;
  width: 100%;
}


/* .project-img > div:hover, .project-img > a:hover {
  opacity: 0;
} */

.project-content {
  grid-column: 6 / -1;
  grid-row: 1 / -1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 100;
}

.project-title {
  font-weight: bold;
  text-align: right;
  font-size: 1.5em;
  margin-bottom: 5%;
  text-decoration: underline;
  color: var(--light);
}

.project-title:hover {
  color: var(--electric-green);
}

.project-desc {
  background: var(--green-blue);
  padding: 3%;
  margin-bottom: 2%;
}

.project-tags {
  text-align: right;
  margin-bottom: 2%;
  width: 80%;
  color: var(--light-blue)
}

.project-links {
  height: 20px;
  text-align: right;
  width: 40%;
}

.project-github > img {
  height: 100%;
  width: auto;
}

.project-link > img {
  height: 100%;
  width: auto
}

.project-github {
  padding-right: 5%;
}

/* RIGHT PROJECT CARD */

.right .project-img {
  grid-column: 6 / -1;
}

.right .project-content {
  grid-column: 1 / 7;
  align-items: flex-start;
}

.right .project-links {
  text-align: left;
}

.right .project-tags {
  text-align: left;
}

@media (max-width: 678px) {

  .project-card {
    display: block;
    position: relative;
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 5%;
  }

  .project-img {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
  }

  .project-img > div, .project-img > a {
    opacity: 0;
  }
  
  .project-content {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,0.8);
  }

  .arrow {
    /*visibility: hidden;*/
  }

  .project-title, .project-tags, .project-links, .right .project-tags, .right .project-links, .right .project-title {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .project-tags {
    font-size: .75em;
  }

  .project-desc {
    font-size: .75em;
    background: transparent;
    text-align: center;
  }

  .project-title {
    margin-top: 1%;
  }
}

.container{
    display: flex;
    overflow: hidden;
    border-radius: 6px;
    width: 100%;
    height: 100%;
    position: relative; 
}
.container:hover *{
    display: flex;
}

.container > .icon-button{
    display: none;
}
.container:hover > .icon-button{
    z-index: 10001;
    display: flex;
}
.container:hover > .dots-container{
    z-index: 10001;
}

.container:hover > .styled-image{
    z-index: 10000;
}

.right-button{
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.56;
  background: black !important;
  border-radius: 6px;
  outline: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 6px;
  /* z-index: 1000000; */

}

.right-button:hover{
    opacity: 0.8;
}

.left-button{
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.56;
    background: black !important;
    border-radius: 6px;
    outline: none;
    border: none;
    color: white;
    cursor: pointer;
    padding: 6px;
    /* z-index: 1000000; */
  }

  .left-button:hover{
      opacity: 0.8;
  }

  .dots-container{
    position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  bottom: 8px;
  grid-gap: 4px;
  gap: 4px;
  justify-content: center;
  flex-direction: row;
  }

  .active-dot{
width: 6px;
  height: 6px;
  border-radius: 50%;
  opacity: 1;
  background: white;
  }

  .dot{
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: white;
    opacity: 0.56;
  }

  .styled-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  
 
.carousel-container {
  width: 100%;
  height: 300px;
  position: relative;
  overflow: hidden;
}

.arrow {
  text-shadow: 1px 1px 1px #fff;
  z-index: 200;
  line-height: 300px;
  text-align: center;
  position: absolute;
  top: 0;
  width: 10%;
  font-size: 3em;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.right-arrow {
  left: 90%;
}

.left-arrow {
  left: 0;
}

@media (max-width: 400px) {
  .carousel-container {
    height: 225px;
  }
}

.btn {
  text-decoration: none;
  color: var(--light-blue);
  padding: 10px;
  border: solid var(--light-blue);
  border-radius: 5px;
}

.btn:hover {
  color: var(--electric-green);
  border: solid var(--electric-green);
}
#experience > .section-body > .experience {
  margin-bottom: 5%;
}

#experience > .section-body {
}
.step-container{
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
}

.step-left-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.step-right-container{
    display: flex;
    flex-direction: column;

}

.circle{
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: darkgrey;
}
@media only screen and (max-width: 600px) {
      .line{
        height: 300px !important
      }  
}

.line{
    width: 1px;
    flex-shrink: 0;
    height: 120px;
    background-color: gray;
  
}

.container{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.bullet-container{
    display: flex;
    flex-direction: column;
    grid-gap: 6px;
    gap: 6px;
    margin-top: 10px
}

.bullet{
    line-height: 15px;
}

.image-container{
    display: flex;
    flex-direction: row;
    grid-gap: 15px;
    gap: 15px;
    align-items: center;
    margin-top: 10px;
}

.styled-image-inline{
    height: 130px;
    aspect-ratio: 16/9;
    object-fit: cover;
    border-radius: 5px;
}
.title{
    margin-top: -12px;
    margin-bottom: -6px;
    font-weight: 600;
    font-size: 36;
    line-height: 42px;
}
.subtitle-1{
    font-weight: 500;
    font-size: 20;
    line-height: 26px;
}
.subtitle-2{
    font-weight: 400;
    font-size: 16;
    line-height: 20px;
    color: gray;
}
