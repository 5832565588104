#projects > .section-body > .btn {
  margin: 5% auto;
}

.react-reveal {
  width: 100%;
  height: 100%;
}

#projects-body {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

[class~='project-card']:last-of-type {
  border-bottom: none;
}
