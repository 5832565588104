.carousel-container {
  width: 100%;
  height: 300px;
  position: relative;
  overflow: hidden;
}

.arrow {
  text-shadow: 1px 1px 1px #fff;
  z-index: 200;
  line-height: 300px;
  text-align: center;
  position: absolute;
  top: 0;
  width: 10%;
  font-size: 3em;
  cursor: pointer;
  user-select: none;
}

.right-arrow {
  left: 90%;
}

.left-arrow {
  left: 0;
}

@media (max-width: 400px) {
  .carousel-container {
    height: 225px;
  }
}
