.step-container{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.step-left-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.step-right-container{
    display: flex;
    flex-direction: column;

}

.circle{
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: darkgrey;
}
@media only screen and (max-width: 600px) {
      .line{
        height: 300px !important
      }  
}

.line{
    width: 1px;
    flex-shrink: 0;
    height: 120px;
    background-color: gray;
  
}

.container{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.bullet-container{
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-top: 10px
}

.bullet{
    line-height: 15px;
}

.image-container{
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    margin-top: 10px;
}

.styled-image-inline{
    height: 130px;
    aspect-ratio: 16/9;
    object-fit: cover;
    border-radius: 5px;
}
.title{
    margin-top: -12px;
    margin-bottom: -6px;
    font-weight: 600;
    font-size: 36;
    line-height: 42px;
}
.subtitle-1{
    font-weight: 500;
    font-size: 20;
    line-height: 26px;
}
.subtitle-2{
    font-weight: 400;
    font-size: 16;
    line-height: 20px;
    color: gray;
}