.body-text {
  text-align: center;
  margin-bottom: 1%;
}

#about-img {
  min-width: 300px;
  max-width: 500px;
  height: auto;
  border-radius: 5%;
  overflow: hidden;
}

#about-img > img {
  width: 100%;
  height: 100%;
}