.project-card {
  display: inline-grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-column-gap: 5px;
  width: 100%;
  height: 275px;
  padding-bottom: 2.5%;
  margin-bottom: 2.5%;
  border-bottom: dotted;
}

.project-img {
  grid-column: 1 / 7;
  grid-row: 1 / -1;
  overflow: hidden;
  /* border: solid var(--green-blue); */
  /*border-radius: 5px;*/
  /* position: relative; */
}

.project-img > img {
  height: 100%;
  width: 100%;
}


/* .project-img > div:hover, .project-img > a:hover {
  opacity: 0;
} */

.project-content {
  grid-column: 6 / -1;
  grid-row: 1 / -1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 100;
}

.project-title {
  font-weight: bold;
  text-align: right;
  font-size: 1.5em;
  margin-bottom: 5%;
  text-decoration: underline;
  color: var(--light);
}

.project-title:hover {
  color: var(--electric-green);
}

.project-desc {
  background: var(--green-blue);
  padding: 3%;
  margin-bottom: 2%;
}

.project-tags {
  text-align: right;
  margin-bottom: 2%;
  width: 80%;
  color: var(--light-blue)
}

.project-links {
  height: 20px;
  text-align: right;
  width: 40%;
}

.project-github > img {
  height: 100%;
  width: auto;
}

.project-link > img {
  height: 100%;
  width: auto
}

.project-github {
  padding-right: 5%;
}

/* RIGHT PROJECT CARD */

.right .project-img {
  grid-column: 6 / -1;
}

.right .project-content {
  grid-column: 1 / 7;
  align-items: flex-start;
}

.right .project-links {
  text-align: left;
}

.right .project-tags {
  text-align: left;
}

@media (max-width: 678px) {

  .project-card {
    display: block;
    position: relative;
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 5%;
  }

  .project-img {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
  }

  .project-img > div, .project-img > a {
    opacity: 0;
  }
  
  .project-content {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,0.8);
  }

  .arrow {
    /*visibility: hidden;*/
  }

  .project-title, .project-tags, .project-links, .right .project-tags, .right .project-links, .right .project-title {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .project-tags {
    font-size: .75em;
  }

  .project-desc {
    font-size: .75em;
    background: transparent;
    text-align: center;
  }

  .project-title {
    margin-top: 1%;
  }
}
