#footer {
  position: fixed;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  align-items: center;

  z-index: 100;
}

.footer-img {
  padding: 5px;
  height: 36px;
  width: auto;
  filter: brightness(0) invert(1);
}

@media (max-width: 678px) {
  #footer {
    flex-direction: column;
  }
}


